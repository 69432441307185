import { CssBaseline, ThemeProvider } from "@mui/material"
import {
  AppContextProvider,
  DialogProvider,
  FeatureFlagsProvider,
  QueryClient,
  QueryClientProvider,
  ROLES,
  type Role,
  SidePanelProvider,
  ToasterProvider,
  useSso,
} from "@pharmupp/p3-front-commons"
import * as P3Themes from "@pharmupp/p3-themes"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import { Router } from "./Router"
import { globalStyles } from "./components/GlobalStyles"

const getThemeFromRoles = (roles: Role[] = []) => {
  switch (true) {
    case roles.includes(ROLES.ADMIN) || roles.includes(ROLES.ADHERENT_ADVISOR):
      return P3Themes.admin
    case roles.includes(ROLES.EXTERN_CONTACT_LABORATORY):
      return P3Themes.laboratory
    default:
      return P3Themes.adherent
  }
}

const queryClient = new QueryClient()

export function App() {
  const { sso } = useSso({
    ...window.KEYCLOAK_CONF,
    // Mock auth
    isMocked: import.meta.env.VITE_MOCKED === "true",
    ...("user-mock" in window.localStorage && {
      mockConf: {
        tokenParsed: JSON.parse(window.localStorage.getItem("user-mock")!),
      },
    }),
  })
  const roles = sso?.getRoles?.()
  const theme = getThemeFromRoles(roles)

  if (!sso) return null

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {globalStyles}
        <FeatureFlagsProvider>
          <AppContextProvider sso={sso}>
            <QueryClientProvider client={queryClient}>
              <ToasterProvider>
                <DialogProvider>
                  <SidePanelProvider>
                    <Router />

                    {!import.meta.env.PROD && (
                      <ReactQueryDevtools initialIsOpen={false} />
                    )}
                  </SidePanelProvider>
                </DialogProvider>
              </ToasterProvider>
            </QueryClientProvider>
          </AppContextProvider>
        </FeatureFlagsProvider>
      </ThemeProvider>
    </div>
  )
}
