import {
  Box,
  Button,
  Divider,
  Drawer,
  type DrawerProps,
  Stack,
  styled,
} from "@mui/material"
import type { MouseEvent, PropsWithChildren, ReactNode } from "react"
import { X } from "react-feather"

export interface SidePanelProps extends SidePanelStyle {
  open: boolean
  onClose: () => void
  title?: ReactNode
  footer?: ReactNode
}
interface SidePanelStyle {
  size?: "full" | "medium"
  variant?: Extract<DrawerProps["variant"], "temporary" | "persistent">
  elevation?: DrawerProps["elevation"]
}

export const SidePanel = ({
  open,
  onClose,
  title,
  footer,
  variant = "persistent",
  size = "medium",
  elevation = 16,
  children,
}: PropsWithChildren<SidePanelProps>) => {
  const handleClose = (
    event: MouseEvent | MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation()
    onClose()
  }

  return (
    <SidePanelEl
      open={open}
      onClose={(event: MouseEvent) => handleClose(event)}
      anchor="right"
      size={size}
      variant={variant}
      elevation={elevation}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={1.8}
        py={1}
      >
        {title}
        <CloseButton onClick={(e) => handleClose(e)}>
          <X />
        </CloseButton>
      </Stack>
      <Divider />
      <Box sx={{ overflowY: "auto", flex: 1 }} p={2}>
        {children}
      </Box>
      {footer && (
        <>
          <Divider />
          <Box p={1}>{footer}</Box>
        </>
      )}
    </SidePanelEl>
  )
}

const SidePanelEl = styled(Drawer)<SidePanelStyle>(
  ({ theme, size, elevation, variant }) => ({
    "& .MuiDrawer-paper": {
      ...(size === "medium" && {
        top: "auto",
        bottom: 10,
        height: "88%",
      }),
      width: "clamp(430px, 35%, 500px)",
      ...(variant === "persistent" &&
        elevation && {
          boxShadow: theme.shadows[elevation],
        }),
    },
  }),
)

const CloseButton = styled(Button)(({ theme }) => ({
  minWidth: 0,
  width: "36px",
  aspectRatio: 1,
  borderRadius: "50%",
  padding: theme.spacing(0.5),
}))
